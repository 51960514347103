import React, { FC } from 'react';
import { Link } from 'gatsby';
import { getSrcSet } from 'gatsby-plugin-image';
import kebabCase from 'lodash/kebabCase';
import clsx from 'clsx';

import { Wrapper } from './styles';
import { getElementAttributes, imageAttributeKeys } from '@/utils/contentful';
import { ContentfulSection } from '../types.d';
import { ContentfulFigure } from '@/types';

export interface LogoSection extends ContentfulSection {
  image: ContentfulFigure;
  alignment?: 'Left' | 'Center' | 'Right';
  position?: 'Absolute' | 'Static';
}

/**
 * Logo section
 */
const Logo: FC<LogoSection> = ({
  image,
  alignment = `Left`,
  position = `Absolute`,
}) => (
  <Wrapper
    className={clsx(
      `position-${kebabCase(position)}`,
      `alignment-${kebabCase(alignment)}`,
    )}
  >
    <div>
      <Link to="/">
        <img
          srcSet={
            getSrcSet(image.image.gatsbyImageData) || image.image.file.url
          }
          alt={image.image.title}
          {...getElementAttributes(image.attributes, imageAttributeKeys)}
        />
      </Link>
    </div>
  </Wrapper>
);

export default Logo;
