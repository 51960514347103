// import { css } from '@emotion/react';
import styled from '@emotion/styled';

// import { phabletAndBelow } from '@/app/common/style/mixins';

export const Wrapper = styled.div`
  position: relative;

  > div {
    z-index: 1;
    padding: 64px 0;
  }

  &.position-absolute {
    > div {
      position: absolute;
    }
  }

  &.alignment-left {
    > div {
      left: 0;
    }
  }

  &.alignment-right {
    > div {
      right: 0;
    }
  }

  &.alignment-center {
    > div {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }

  img {
    height: 32px;
  }
`;
